<template>
  <div>
    <a-layout>
      <a-layout-header class="mendian-page-header flex alcenter center">
        <div class="flex space alcenter page-header">
          <div></div>
          <div>
            <a-popover placement="bottom">
              <template slot="content">
                <div @click="showPassword = !0">
                  <a-button type="link" icon="edit" style="color: #7a8599"
                    >修改密码</a-button
                  >
                </div>
                <div class="mt4" @click="loginout">
                  <a-button
                    type="link"
                    icon="close-circle"
                    style="color: #7a8599"
                    >退出登录</a-button
                  >
                </div>
              </template>
              <div class="flex alcenter">
                <a-avatar
                  :src="
                    accountUser.face ||
                    require(`../assets/image/woyaoyou_head_defult@2x.png`)
                  "
                />
                <a-button type="link" class="cl-main ft14">
                  {{ accountUser.username
                  }}<a-icon class="ft12" type="caret-down" theme="filled" />
                </a-button>
              </div>
            </a-popover>
          </div>
        </div>
      </a-layout-header>
      <a-layout-content
        style="
          height: 93vh;
          background: #f7f8fa;
          min-width: 1280px;
          overflow-y: auto;
        "
      >
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
    <edit-password
      :visible="showPassword"
      @cancel="showPassword = !1"
      v-if="showPassword"
    ></edit-password>
  </div>
</template>

<script>
import editPassword from "./store/components/password/edit";
export default {
  components: {
    editPassword,
  },
  data() {
    return {
      siteInfo: null,
      accountUser: {},
      showPassword: !1,
    };
  },
  created() {
    this.getSiteInfo();
    this.getAccountUser();
  },
  methods: {
    getSiteInfo() {
      this.$http
        .papi("platform/siteInfo")
        .then((res) => {
          this.siteInfo = res.site;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getAccountUser() {
      this.$http
        .papi("platform/account/layout")
        .then((res) => {
          this.accountUser = res.account;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    loginout() {
      localStorage.removeItem("account-token");
      location.href = process.env.VUE_APP_ACCOUNT_URL;
    },
  },
};
</script>

<style lang="less">
.mendian-page-header {
  background: #ffffff;
  min-width: 1280px;
  .page-header{
    width: 1200px;
  }
}
</style>