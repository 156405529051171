<template>
  <div>
    <a-modal
      :title="getTitle"
      :width="400"
      :visible="visible"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="onSubmit"
          :loading="confirmLoading"
        >
          保存
        </a-button>
      </template>
      <a-spin :spinning="loading">
        <a-form-model
          :model="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-model-item required label="输入密码">
            <a-input
              style="width: 240px"
              v-model="form.pwd"
              type="password"
            />
          </a-form-model-item>
           <a-form-model-item required label="再次确认">
            <a-input
              style="width: 240px"
              v-model="form.repwd"
              type="password"
            />
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
      confirmLoading: false,
      form: {
        pwd: "",
        repwd: ""
      },
    };
  },
  computed: {
    getTitle() {
      return "设置密码";
    },
  },
  created() {
    
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },

    onSubmit() {
      if (this.confirmLoading == true) return;
      this.confirmLoading = true;
      this.$http
        .papi("platform/account/editPwd", this.form)
        .then((res) => {
          this.$message.success("密码设置成功", 1, () => {
            this.confirmLoading = false;
            this.handleCancel();
          });
        })
        .catch((res) => {
          console.log(res);
          this.confirmLoading = false;
        });
    },
  },
};
</script>

<style>
</style>
